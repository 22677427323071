<template>
    <section id="banner">
        <div class="carousel">
            <div :class="`container mu`">
                <img :src="imageUrl + 'MU_Desktop_new.png'" alt="mu-logo" class="mu-logo banner-web" />
                <img :src="imageUrl + 'MU_Mobile_new.png'" alt="mu-logo" class="mu-logo banner-mobile" />
                <div class="banner-content-wrapper">
                    <div class="banner-content">
                        <img :src="imageUrl + 'logo-brand-d-gx-mu-vertical@2x.png'">
                        <p v-html="$t('banner.header')"></p>
                        <a class="btn primary" @click="goToMu">{{ $t('banner.learn-more') }} <img
                                :src="imageArrow + 'arrow.svg'" class="banner-mobile"></a>
                    </div>
                </div>
            </div>
            <!-- <carousel :items-to-show="1" :autoplay="5000" :wrap-around="false">
                <slide v-for="(item, index) in setInit" :key="index" class="carousel-item">
                    <div v-if="item == 'HKFW'" class="container hkfw">
                        <img :src="imageUrl + item + '_Desktop_new.png'" alt="HKFW" class="banner-web" />
                        <img :src="imageUrl + item + '_Mobile_new.png'" alt="HKFW" class="banner-mobile" />
                        <div class="banner-content-wrapper">
                            <div class="banner-content">
                                <img :src="imageUrl + 'HKFW_Brand_PC.png'">
                                <h1 v-html="$t('banner.hongkongEvent')"></h1>
                                <a class="btn primary"
                                    href="https://blog.doo.com/blog/hong-kong-fintech-week-2023-recap/">{{
                $t('banner.learn-more') }} <img :src="imageArrow + 'arrow.svg'"
                                        class="banner-mobile"></a>
                            </div>
                        </div>
                    </div>
            </div> -->
            <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true">
                <slide v-for="(item, index) in setInit" :key="index" class="carousel-item">
                    <div v-if="item == 'mu'" class="container mu">
                        <img :src="imageUrl + 'MU_Desktop_new.png'" alt="mu-logo" class="mu-logo banner-web" />
                        <img :src="imageUrl + 'MU_Mobile_new.png'" alt="mu-logo" class="mu-logo banner-mobile" />
                        <div class="banner-content-wrapper">
                            <div class="banner-content">
                                <img :src="imageUrl + 'logo-brand-d-gx-mu-vertical@2x.png'">
                                <p v-html="$t('banner.header')"></p>
                                <a class="btn primary" @click="goToMu">{{ $t('banner.learn-more') }} <img
                                        :src="imageArrow + 'arrow.svg'" class="banner-mobile"></a>
                            </div>
                        </div>
                    </div>
                    <div v-if="item == 'DSC'" :class="`container DSC`">
                        <img :src="`${imageUrl}DSC_BSC_Banner_${$i18n.locale}.png`" alt="DSC-banner"
                            class="DSC-banner banner-web" />
                        <img :src="`${imageUrl}DSC_Mobile_Banner_${$i18n.locale}.png`" alt="DSC-banner"
                            class="DSC-banner banner-mobile" />
                        <div class="banner-content-wrapper" :class="$i18n.locale">
                            <div class="banner-content">
                                <img :class="$i18n.locale" class="DSC-logo" :src="imageUrl + 'DSC_PC_logo.png'">
                                <p :class="$i18n.locale" class="header" v-html="$t('banner.headerDSC')"
                                    v-if="!isMobile"></p>
                                <p :class="$i18n.locale" class="header" v-html="$t('banner.headerDSCMobile')" v-else>
                                </p>
                                <p :class="$i18n.locale" class="sub-header" v-html="$t('banner.header-sub-DSC')"></p>
                                <a :class="$i18n.locale" class="btn primary"
                                    :href="`https://blog.doo.com/${$i18n.locale}/news/doo-group-and-dcs-card-centre-jointly-launch-the-platinum-dragon-card/`">{{
                $t('banner.learn-more') }} <img :src="imageArrow + 'arrow.svg'"
                                        class="banner-mobile"></a>
                            </div>
                        </div>
                    </div>
                    <div v-if="item == 'token_dubai'" :class="`container token_dubai`">
                        <img :src="imageUrl + 'token_dubai_pc_banner_new.png'" alt="mu-logo"
                            class="mu-logo banner-web" />
                        <img :src="imageUrl + 'token_dubai_mobile_banner_new.png'" alt="mu-logo"
                            class="mu-logo banner-mobile" />
                        <div class="banner-content-wrapper">
                            <div class="banner-content">
                                <img :src="imageUrl + 'token_dubai_brand_logo_new.png'">
                                <p class="token_dubai_title" v-html="$t('token2049.title')"></p>
                                <a class="btn token-dubai-btn" :href="$t('token2049.tokenDubaiLink')">{{
                $t('banner.learn-more') }} <img :src="imageUrl + 'arrow-white.png'"
                                        class="banner-mobile arrow-white"></a>
                            </div>
                        </div>
                    </div>
                </slide>
                <template #addons>
                    <pagination />
                </template>
            </carousel> -->
        </div>
    </section>
    <section class="home-better-future">
        <div class="container">
            <div class="content">
                <p class="header" v-t="'banner.header-sub'"></p>
                <p class="description" v-t="'banner.description-sub'"></p>
            </div>
        </div>
        <img :src="imageUrl + 'Nasdaq-mobile-new1.png'" alt="mu-logo" class="banner-tablet-mobile" />
    </section>
</template>

<script scoped>
import { useI18n } from 'vue-i18n';
// import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
    setup() {
        // let currentSet = 0;
        // let setA = ["mu"];

        // const date = new Date();
        // const minus = String(date.getMinutes()).padStart(2, '0');

        // switch (true) {
        //     case minus > 30:
        //         currentSet = 1;
        //         break;
        //     default:
        //     currentSet = 0;
        // }

        // let setList = [];
        // setList = [setA];

        // let setInit = setList[currentSet];

        const lang = computed(() => {
            return window.location.pathname.split('/')[1]
        })

        return {
            // setInit
        };
    },
    components: {
        // Carousel,
        // Slide,
        // Pagination,
        // Navigation,
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/",
            imageArrow: "https://www.dooprime.com/wp-content/themes/DP3-1104/style/images/homepage/new/",
            isMobile: false
        }
    },
    methods: {
        goToMu() {
            let url = window.location.origin + "/" + this.$i18n.locale + "/partnership/manutd"
            if (this.$i18n.locale === 'sc')
                url = window.location.origin + "/zh/partnership/manutd"

            window.open(url, '_blank').focus();
        },
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}

</script>

<style lang="less">
@import '../../less/mediaQuery.less';
@media only screen and (min-width:835px) {
    .banner-mobile {
        display: none !important;
    }
}

@media only screen and (max-width:834px) {
    .banner-web {
        display: none !important;
    }
}

.arrow-white {
    width: 10px;
    height: 16px;
    margin-left: 8px;
}

html[lang='en'] .arrow-white {
    vertical-align: -2px !important;
}

html[lang='sc'] .arrow-white,
html[lang='tc'] .arrow-white {
    vertical-align: -1px !important;
}

#banner {
    width: 100vw;
    text-align: left;
    position: relative;
    display: inline-block;
    margin-top: 113px;

    @media only screen and (max-width:1099px) and (min-width:835px) {
        margin-top: 71px;
    }

    @media @mobile-screen {
        margin-top: 72px;
    }

    .carousel {
        position: relative;

        .carousel__pagination {
            position: absolute;
            bottom: 16px;
            left: 0;
            width: 100%;
            text-align: center;

            .carousel__pagination-item .carousel__pagination-button {
                margin: 3px;
                padding: 0;

                &.carousel__pagination-button--active {
                    &:after {
                        background-color: #e03323;
                    }
                }

                &:after {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        padding: 0;
        max-width: 2560px !important;
        text-align: left;
        overflow: hidden;

        &.anniversary {
            .banner-content-wrapper {
                @media only screen and (min-width: 835px) {
                    padding-top: 200px;
                }

                .banner-content {
                    h1 {
                        margin-bottom: 20px;

                        @media only screen and (max-width: 834px) {
                            padding-top: 32px;
                        }
                    }

                    p {
                        font-size: 20px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.4;
                        letter-spacing: normal;
                        color: #3e3a39;
                        margin-bottom: 0;

                        @media only screen and (min-width: 835px) {
                            max-width: 470px;
                        }
                    }
                }
            }
        }

        &.sff {
            .banner-content-wrapper {
                @media only screen and (min-width: 835px) {
                    padding-top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .banner-content {
                    @media only screen and (max-width: 834px) {
                        padding-top: 26px;
                    }

                    >img {
                        max-width: 421px;
                        margin-bottom: 40px;

                        @media only screen and (max-width: 834px) {
                            margin-bottom: 17.4px;
                            max-width: 234px;
                        }
                    }

                    h1 {
                        padding-top: 0;
                        margin-bottom: 40px;
                        color: #000;

                        @media only screen and (max-width: 834px) {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }

        &.hkfw {
            .banner-content-wrapper {
                @media only screen and (min-width: 835px) {
                    padding-top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .banner-content {
                        >img {
                            margin-bottom: 40px !important;
                            max-width: 332px !important;
                        }

                        >h1 {
                            margin-bottom: 40px !important;
                        }

                        >.banner-web {
                            margin-bottom: 40px !important;
                        }
                    }
                }

                .banner-content {
                    padding-top: 24px;

                    >img {
                        max-width: 200px;
                        margin-bottom: 16px;
                    }

                    >h1 {
                        padding-top: 0;
                        margin-bottom: 16px;
                        color: #3e3a39;
                    }

                    >.banner-web {
                        display: block !important;
                        margin-bottom: 16px;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #3e3a39;
                    }
                }
            }
        }

        &.DSC {

            .banner-content-wrapper {

                &.sc,
                &.tc {
                    padding-top: 72px;

                    @media only screen and (max-width: 834px) {
                        padding-top: 24px;
                    }
                }



                .banner-content {



                    .DSC-logo {
                        width: 304px;
                        height: 48px;

                        @media only screen and (max-width: 834px) {
                            margin-top: 16px;

                            width: 176px;
                            height: 28px;
                        }

                        &.sc,
                        &.tc {
                            @media only screen and (max-width: 834px) {
                                margin-bottom: 12px;
                            }
                        }
                    }

                    .header {
                        max-width: 645px;
                        font-size: 56px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.4;
                        letter-spacing: normal;
                        color: #3e3a39;
                        margin-bottom: 24px;
                        margin-top: 40px;

                        @media only screen and (max-width: 834px) {
                            font-size: 28px;
                            padding: 0 26.5px;
                            height: auto;
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        @media only screen and (max-width:1099px) and (min-width:835px) {
                            font-size: 36px;
                        }

                        &.sc,
                        &.tc {
                            @media only screen and (max-width: 834px) {
                                margin-bottom: 12px;
                            }
                        }

                        &.en {
                            @media only screen and (max-width: 834px) {
                                width: 375px;
                                font-size: 28px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.36;
                                letter-spacing: normal;
                                text-align: center;
                            }
                        }
                    }

                    .sub-header {
                        @media only screen and (max-width: 834px) {
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            text-align: center;
                            color: #3e3a39;
                        }

                        &.sc,
                        &.tc {

                            margin-bottom: 72px;

                            @media only screen and (max-width: 834px) {
                                margin-bottom: 12px;
                            }
                        }

                        &.en {
                            margin-bottom: 40px;

                            @media only screen and (max-width: 834px) {
                                width: 322px;
                                margin-bottom: 0px;
                            }
                        }

                    }

                    .primary {
                        @media only screen and (max-width: 834px) {
                            min-height: auto;
                            margin-bottom: 8px;
                        }

                        &.sc,
                        &.tc {
                            @media only screen and (max-width: 834px) {
                                margin-bottom: 12px;
                            }
                        }
                    }

                    .bottom-text {
                        margin-top: 36px;
                        margin-bottom: 24px;
                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #000018;
                        opacity: 0.5;

                        &.sc,
                        &.tc {
                            margin-top: 69px;
                        }

                        @media only screen and (max-width: 834px) {

                            &.sc,
                            &.tc {
                                margin-top: 20px;
                            }

                            margin-top: 0px;
                            margin-bottom: 0;
                            font-family: MicrosoftYaHei;
                            font-size: 10px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000018;
                            width: 203px;
                        }
                    }



                }

            }
        }



        >.banner-mobile {
            min-height: 600px !important;
            object-fit: cover;
        }

        >.banner-web {
            min-height: 600px !important;
            object-fit: cover;

            @media only screen and (max-width:1200px) and (min-width:835px) {
                min-height: 450px !important;
            }
        }

        .banner-content-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            /**@media only screen and (max-width:1200px) and (min-width:835px) {
                padding-top: 4.5vw;
            }**/
            @media only screen and (min-width:1201px) {
                padding-top: 140px;
                margin-left: 135px;
            }

            @media only screen and (max-width:1200px) and (min-width:835px) {
                display: flex;
                align-items: center;
                margin-left: 32px;
            }

            .banner-content {
                max-width: 1600px;
                width: 100%;
                margin: 0 auto;

                @media only screen and (max-width:834px) {
                    text-align: center;
                }

                h1 {
                    font-size: 48px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #3e3a39;
                    margin-bottom: 24px;
                    width: fit-content;

                    /**@media only screen and (max-width:1200px) and (min-width:835px) {
                        font-size: 2.1vw;
                        margin-bottom: 1vw;
                    }**/
                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        font-size: 32px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #3e3a39;
                        margin-bottom: 16px;
                    }

                    @media only screen and (max-width:834px) {
                        padding-top: 36px;
                        font-size: 28px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #3e3a39;
                        margin: 0 auto 16px;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #3e3a39;
                    width: fit-content;
                    margin-bottom: 40px;

                    /**@media only screen and (max-width:1200px) and (min-width:835px) {
                        font-size: 0.98vw;
                        margin-bottom: 1.7vw;
                    }**/
                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        max-width: 220px;
                        margin-bottom: 32px;
                    }

                    @media only screen and (max-width:834px) {
                        text-align: center;
                        max-width: 400px;
                        width: 100%;
                        color: #3e3a39;
                        margin: 0 auto 16px;
                    }

                    span {
                        &.red {
                            font-size: 20px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            color: #e03323;
                        }
                    }
                }
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                min-height: 56px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                text-align: center;
                border-radius: 0px;
                border: 1px solid transparent;

                /**@media only screen and (max-width:1200px) and (min-width:835px) {
                    width: 7.8vw;
                    min-height: 2.5vw;
                    font-size: 0.7vw;
                }**/
                @media only screen and (max-width:1200px) and (min-width:835px) {
                    width: 150px;
                    min-height: 48px;
                }

                @media only screen and (max-width:834px) {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #e03323;
                    padding: 0;
                    height: auto;
                    min-width: auto;
                    height: 21px;
                    display: block;
                    margin: auto;
                }

                img {
                    display: inline-block;
                    vertical-align: -3px;
                }
            }

            .primary {
                @media only screen and (min-width:835px) {
                    background-color: #e03323;
                    border-color: #e03323;
                }
            }
        }

        &.mu {
            .banner-content-wrapper .banner-content {
                >img {
                    max-width: 306px;
                    width: 100%;
                    margin-bottom: 24px;

                    /**@media only screen and (max-width:1200px) and (min-width:835px) {
                        max-width: 15vw;
                        margin-bottom: 1vw;
                    }**/
                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        max-width: 221.9px;
                        margin-bottom: 16px;
                    }

                    @media only screen and (max-width:834px) {
                        max-width: 185.3px;
                        margin-bottom: 16px;
                        padding-top: 24px;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    color: #3e3a39;
                    margin-bottom: 40px;

                    /**@media only screen and (max-width:1200px) and (min-width:835px) {
                        font-size: 0.98vw;
                        margin-bottom: 1.7vw;
                    }**/
                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        margin-bottom: 24px;
                    }

                    @media only screen and (max-width:834px) {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #3e3a39;
                        margin-bottom: 16px;
                    }
                }
            }
        }

        &.token_dubai {
            .banner-web {
                max-height: 600px;
            }

            .token-dubai-btn {
                background-color: #FFFFFF;
                color: #e03323;
            }

            .banner-content-wrapper .banner-content {
                >img {
                    max-width: 306px;
                    width: 100%;
                    margin-bottom: 24px;

                    /**@media only screen and (max-width:1200px) and (min-width:835px) {
                        max-width: 15vw;
                        margin-bottom: 1vw;
                    }**/
                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        max-width: 221.9px;
                        margin-bottom: 16px;
                    }

                    @media only screen and (max-width:834px) {
                        max-width: 185.3px;
                        margin-bottom: 16px;
                        padding-top: 24px;
                    }
                }

                .token_dubai_title {
                    font-size: 56px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    color: #FFFFFF;
                    margin-bottom: 24px;

                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        font-size: 28px;
                        max-width: 300px;
                        margin-bottom: 24px;
                    }

                    @media only screen and (max-width:834px) {
                        font-size: 28px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #FFFFFF;
                        margin-bottom: 16px;
                    }
                }

                .token_dubai_description {
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: normal;
                    color: #000018;
                    margin-bottom: 40px;

                    span {
                        color: #e03323;
                    }

                    @media only screen and (max-width:1200px) and (min-width:835px) {
                        margin-bottom: 24px;
                    }

                    @media only screen and (max-width:834px) {
                        display: none;
                    }
                }

                .token-dubai-btn {
                    @media only screen and (max-width:834px) {
                        background-color: transparent;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

}

.home-better-future {
    position: relative;

    @media only screen and (min-width:950px) {
        min-height: 740px;
        display: flex;
        align-items: center;
        background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Nasdaq_Desktop_new_2.png') no-repeat center top;

        .banner-tablet-mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 949px) {
        margin-top: 80px;
        padding: 0;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    .content {
        max-width: 517px;

        @media only screen and (max-width:1100px) and (min-width:950px) {
            max-width: 434px;
        }

        @media only screen and (min-width:950px) {

            float: right;
            padding-bottom: 16px;
        }

        @media only screen and (max-width: 949px) {
            max-width: initial;
        }

        .header {
            text-align: left;
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3a39;
            margin: 0;
            margin-bottom: 24px;

            @media only screen and (max-width: 949px) {
                font-size: 28px;
                margin: 0;
                margin-bottom: 16px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                max-width: initial;
            }
        }

        .description {
            text-align: left;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #3e3a39;

            @media only screen and (max-width: 949px) {
                width: 100%;
                font-size: 16px;
                margin: auto;
                padding-bottom: 16px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
                max-width: initial;
            }
        }
    }

    img {
        max-width: 100%;
        width: 100%;
        min-height: 380px;
        object-fit: cover;
    }
}
</style>